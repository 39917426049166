<template>
  <div class="app-container app-theme-white">
    <div class="app-main">
      <div class="app-main__outer">
        <div class="app-main__inner">
          <div class="d-flex justify-content-center align-items-center">
            <div class="logo-inverse" :class="logoClass"></div>
          </div>
          <div class="tab-content mt-4">
            <div
              class="tab-pane tabs-animation fade show active"
              id="tab-content-0"
              role="tabpanel"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <template v-if="recibo">
                        <div class="widget-content p-0 col-12">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left w-100">
                              <div class="widget-heading">
                                <span class="text-secondary">Institución:</span>
                                {{ recibo.empleador }}
                              </div>
                              <div class="widget-heading">
                                <span class="text-secondary">CUIT:</span>
                                {{ recibo.cuit_empleador | cuil }}
                              </div>
                              <hr />
                              <div class="widget-heading">
                                <span class="text-secondary">Empleado:</span>
                                {{ recibo.nombre }}
                              </div>
                              <div class="widget-heading">
                                <span class="text-secondary">CUIL:</span>
                                {{ recibo.cuil | cuil }}
                              </div>
                              <div class="widget-heading">
                                <span class="text-secondary"> N° legajo: </span
                                >{{ recibo.numero_legajo }}
                              </div>
                              <hr />
                              <div class="widget-heading">
                                <span class="text-secondary">Estado: </span>
                                <div
                                  class="badge badge-warning"
                                  v-if="recibo.estado == 'pendiente'"
                                >
                                  Pendiente
                                </div>
                                <div
                                  class="badge badge-success"
                                  v-if="recibo.estado == 'aceptado'"
                                >
                                  Emitido
                                </div>
                              </div>
                              <div class="widget-heading">
                                <span class="text-secondary"
                                  >Fecha Emisión:</span
                                >
                                {{ recibo.fecha_firma | formatDate() }}
                              </div>
                              <div class="widget-heading">
                                <span class="text-secondary">Total: </span>
                                <span class="text-success"
                                  >${{
                                    recibo.neto | numFormat("0,0.00")
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardForm from "@/components/layouts/CardForm";
import TituloComponente from "@/components/layouts/TituloComponente";
// import image from "@/assets/css/utils/images/logo-inverse.png";
import axios from "axios";
export default {
  name: "ValidarRecibo",
  props: {
    titleCard: String,
    iconoTitulo: String,
  },
  components: {
    CardForm,
    TituloComponente,
  },
  computed: {
    logoClass: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return ' desarrollo ';
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return ' ima ';
      } else if (
        window.location.hostname == "nsl.misrecibosdigitales.com.ar"
      ) {
        return ' isnsl ';
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return ' isnsl ';
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " diocesis ";
      } else if (
        window.location.hostname == "isjb.misrecibosdigitales.com.ar"
      ) {
        return " isjb ";
      } else {
        return ' desarrollo ';
      }
    },
  },
  data() {
    return {
      recibo_id: null,
      recibo: null,
      error: false,
      // image: image,
    };
  },
  mounted() {
    let reciboid = this.$route.params.recibo_id;
    this.recibo_id = atob(reciboid);
    this.getRecibo();
  },
  methods: {
    getRecibo() {
      axios
        .get("verificar-recibo/" + this.recibo_id)
        .then((res) => {
          if (res.data.success) {
            this.recibo = res.data.recibo;
          } else {
            this.error = true;
          }
        })
        .catch((e) => {
          console.log(e);
          this.error = true;
        });
    },
  },
};
</script>
