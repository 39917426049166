<template>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i :class="iconoTitulo +' icon-gradient bg-amy-crisp'"></i>
        </div>
        <h2 class="text-secondary">{{titleCard}}</h2>
      </div>
      <div class="page-title-actions" v-if="toCreateAction && toCreateText">
        <router-link :to="toCreateAction" class="btn-shadow btn btn-info">{{toCreateText}}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'TituloComponente',
    props: {
      iconoTitulo: String,
      titleCard: String,
      toCreateAction: String,
      toCreateText: String,
    },
    updated(){
      let title = document.querySelector('title');
      title.innerHTML = `${this.titleCard} - Mis Recibos Digitales`
    }
}
</script>